import React from 'react';
import style from './styles.module.css';
import {ProjectImage} from '../com';
import {Col, Row, Container, Figure} from '@bootstrap-styled/v4';
import {navigate} from '@reach/router';
import {formatDate} from '../../modules/utils';
import Arrow from '../../SvgImg/flechaCircle.svg';

const handlerTitle = title => {
  let titleList = title.split('');
  let firtsLetter = titleList?.shift();

  return firtsLetter?.toUpperCase() + titleList?.join('').toLowerCase();
};

const CardNews = ({imagen, title, categoria, author,title_slug, createdAt}) => {
  return (
    <Row
      className={style.CardNews_container}
      onClick={() => navigate(`/noticias/${title_slug}`)}>
      <div className={style.CardNews}>
        <div className={style.Column}>
          <ProjectImage
            style={{borderRadius: '10px'}}
            src={imagen}
            alt={'cover_image.jpg'}
            size={'small'}
            className={style.imagen_card}
          />
        </div>
        <div className={style.column}>
          <div className={style.content__categoria}>
            <span>{categoria}</span>
          </div>
          <div className={style.content_title}>
            <h2 className={style.title} style={{fontSize:"1.2rem"}}>{handlerTitle(title)}</h2> 
            <p className={style.title_autor}>{`Por: ${author ||  'Construcaribe'}`}</p>
          </div>
          <div className={style.date_logo}>
            <div className={style.date}>{formatDate(createdAt)}</div>
            <div className={style.logo}>
              <img src={Arrow} alt="flecha.svg" />
            </div>
          </div>
        </div>
      </div>
    </Row>
  );
};

export default CardNews;

{
  /* <div className={style.wrapper_card__news}>
            <div className={style.imagen_card}>
             <ProjectImage
                style={{borderRadius: '10px'}}
                src={imagen}
                alt={'cover_image.jpg'}
              />
            </div>
            <div className={style.wrapper_data__card}>
                <div className={style.content__categoria}>
                    <span>{categoria}</span>
                </div>
                <div className={style.content_title}>
                    <p className={style.title}>{title}</p>
                </div>
            </div>
        </div> */
}
