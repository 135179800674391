// import React from 'react';
// import styled from 'styled-components';
// import {Container, Row, Col, H3, Button} from '@bootstrap-styled/v4';
// import Avatar from '@material-ui/core/Avatar';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import Divider from '../components/Divider';
// import ProfileSkeleton from '../components/skeletons/ProfileSkeleton';
// import {navigate} from '@reach/router';
// import {connect} from 'react-redux';
// import Api from '../services/api';
// import {setAuthenticate} from '../actions/notifications';
// import {BASE_BUCKET_URL, LOGO} from '../constants/global';

// const ContentLateralBar = styled.div`
//       display: flex;
//       flex-direction: column;
//       align-items: center;

// `;

// const Company = styled.span`
//   margin-top: 2.0625rem;
//   font-size: 0.9375rem;
// `;

// const Username = styled.p`
//   font-size: 1.7rem !important;
//   font-weight: bold;
//   text-align: center !important;
//   line-height: 110% !important;
// `;

// const Aside = styled(Col)`

//   padding-top: 5.1875rem;
//   padding-bottom: 2.1875rem;

//   @media (max-width: 768px) {
//     padding-top: 2.1875rem;
//   }
// `;

// const NavContainer = styled.nav`
//   max-width: 270px;
//   margin-top: 2rem;
//   display: flex !important;
//   flex-direction: column !important;
//   align-items: center !important;
// `;

// const NavButton = styled(Button)`
//   font-weight: bold !important;
//   outline: 0 !important;
//   color: ${props => (props.active ? '#00B2E3!important' : '#000000!important')};
//   opacity: ${props => (props.active ? '1.0' : '0.2')};
//   padding-left: 0 !important;
// `;

// const ProfileAvatar = styled(Avatar)`
//   width: 136px !important;
//   height: 136px !important;
// `;

// const ContentWrapper = styled(Container)`
//   padding-top: 5.1875rem;
//   padding-bottom: 7.5625rem;
// `;
// const ProfileForm = props => {
//   let {user, loadingUser, setAuthenticate} = props;
//   let {affiliate} = user;
//   const handleLogOut = () => {
//     Api.logout().subscribe({
//       next: () => pushHome(),
//     });
//   };
//   const pushHome = async () => {
//     await navigate('/');
//     setTimeout(() => {
//       if (setAuthenticate) setAuthenticate(false);
//     }, 0);
//   };
//   return (
//     <ContentLateralBar>
//       {loadingUser ? (
//         <ProfileSkeleton />
//       ) : (
//         <React.Fragment>
//           <ProfileAvatar
//             src={
//               affiliate === 'true'
//                 ? user.construction_company && user.construction_company.logo
//                   ? `${BASE_BUCKET_URL}${user.construction_company.logo}`
//                   : LOGO
//                 : //`https://api.adorable.io/avatars/170/${user.first_name}.png`
//                   LOGO
//             }
//           />
//           {affiliate === 'true' && (
//             <Company>
//               {user.construction_company && user.construction_company.name}
//             </Company>
//           )}
//           <Username>{`${user.first_name} ${user.last_name}`}</Username>
//         </React.Fragment>
//       )}
//       <Button
//         color="link"
//         onClick={handleLogOut}
//         className="d-flex align-items-center">
//         <ExitToAppIcon /> <span className="pl-2">Cerrar sesíon</span>
//       </Button>
//       <NavContainer>
//         {affiliate === 'true' && (
//           <NavButton
//             onClick={() => navigate('/admin/mi-cuenta/proyectos')}
//             active={props.location.pathname === '/admin/mi-cuenta/proyectos'}
//             className="w-100 text-left"
//             color="link">
//             Proyectos
//           </NavButton>
//         )}
//         <Divider />
//         <NavButton
//           onClick={() => navigate('/admin/mi-cuenta/contactos')}
//           active={props.location.pathname === '/admin/mi-cuenta/contactos'}
//           className="w-100 text-left"
//           color="link">
//           Contactos
//         </NavButton>
//         <Divider />
//         <NavButton
//           className="w-100 text-left"
//           color="link"
//           onClick={() => navigate('/mis-favoritos')}>
//           Favoritos
//         </NavButton>
//       </NavContainer>
//     </ContentLateralBar>
//   );
// };

// const BaseAccount = ({
//   loadingUser,
//   children,
//   setAuthenticate,
//   user,
//   ...props
// }) => {
//   let {affiliate} = user;
//   affiliate = affiliate == 'true';
//   return (
//     <React.Fragment>
//         <Row>
//       <Aside md={3} className="d-flex flex-column align-items-center">
//         <ProfileForm
//           loadingUser={loadingUser}
//           user={user}
//           setAuthenticate={setAuthenticate}
//           {...props}
//         />
//       </Aside>
//       <Col md={9}>
//         {affiliate && <ContentWrapper>{children}</ContentWrapper>}
//       </Col>
//     </Row>
//     </React.Fragment>
//   );
// };

// const mapStateToProps = state => {
//   return {
//     user: state.auth.user,
//     loadingUser: state.auth.loadingUser,
//   };
// };

// const mapDispatchToProps = dispatch => {
//   return {
//     setAuthenticate: value => dispatch(setAuthenticate(value)),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(BaseAccount);

import React, {useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Button} from '@bootstrap-styled/v4';
import Avatar from '@material-ui/core/Avatar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Divider from '../components/Divider';
import ProfileSkeleton from '../components/skeletons/ProfileSkeleton';
import {navigate} from '@reach/router';
import {connect} from 'react-redux';
import Api from '../services/api';
import {setAuthenticate} from '../actions/notifications';
import {BASE_BUCKET_URL, LOGO} from '../constants/global';

// Styled components
const ContentLateralBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Company = styled.span`
  margin-top: 2.0625rem;
  font-size: 0.9375rem;
`;

const Username = styled.p`
  font-size: 1.7rem !important;
  font-weight: bold;
  text-align: center !important;
  line-height: 110% !important;
`;

const Aside = styled(Col)`
  padding-top: 5.1875rem;
  padding-bottom: 2.1875rem;

  @media (max-width: 768px) {
    padding-top: 2.1875rem;
  }
`;

const NavContainer = styled.nav`
  max-width: 270px;
  margin-top: 2rem;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
`;

const NavButton = styled(Button)`
  font-weight: bold !important;
  outline: 0 !important;
  color: ${props => (props.active ? '#00B2E3!important' : '#000000!important')};
  opacity: ${props => (props.active ? '1.0' : '0.2')};
  padding-left: 0 !important;
`;

const ProfileAvatar = styled(Avatar)`
  width: 136px !important;
  height: 136px !important;
`;

const ContentWrapper = styled(Container)`
  padding-top: 5.1875rem;
  padding-bottom: 7.5625rem;
`;

// Modal styles
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  svg {
    width: 24px;
    height: 24px;
    fill: #333;
  }
`;

const ModalText = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  margin: 0;
  text-align: justify;
`;

const ModalTilte = styled.h4`
  text-align: center;
`;

// Popup component
const Popup = ({onClose}) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M18.3 5.71a1 1 0 00-1.42 0L12 10.59 7.12 5.7a1 1 0 00-1.41 1.42L10.59 12l-4.88 4.88a1 1 0 101.41 1.42L12 13.41l4.88 4.88a1 1 0 001.42-1.42L13.41 12l4.88-4.88a1 1 0 000-1.41z" />
          </svg>
        </CloseButton>
        <ModalTilte>¡Importante!</ModalTilte>
        <br/>
        <ModalText>
          La información publicada en Construcaribe es responsabilidad exclusiva
          del constructor pautante. Camacol no garantiza la precisión,
          integridad o vigencia de los datos expuestos, ni asume responsabilidad
          alguna por el uso o interpretación de dicha información.
          <br />
          Los constructores pautantes son responsables de revisar y gestionar
          adecuadamente todo el contenido que publiquen en la plataforma,
          asegurándose de que cumpla con las normativas aplicables y sea
          correctamente actualizado.
          <br />
          <br/>
          Cualquier perjuicio derivado del uso de la información será
          responsabilidad exclusiva del constructor pautante.
          <br/>
          <br/>
          <ModalText>
            Para mas información, consulte las  <a
              target="_blank"
              href="/documents/RESPONSABILIDAD_DE_CONSTRUCARIBE_EN_LA_PLATAFORMA_DE_CONSTRUCARIBE.pdf">
              <b>Políticas de privacidad.</b>
            </a>
          </ModalText>
        </ModalText>
      </ModalContent>
    </ModalOverlay>
  );
};

// ProfileForm component
const ProfileForm = props => {
  let {user, loadingUser, setAuthenticate} = props;
  let {affiliate} = user;

  const handleLogOut = () => {
    Api.logout().subscribe({
      next: () => pushHome(),
    });
  };

  const pushHome = async () => {
    await navigate('/');
    setTimeout(() => {
      if (setAuthenticate) setAuthenticate(false);
    }, 0);
  };

  return (
    <ContentLateralBar>
      {loadingUser ? (
        <ProfileSkeleton />
      ) : (
        <React.Fragment>
          <ProfileAvatar
            src={
              affiliate === 'true'
                ? user.construction_company && user.construction_company.logo
                  ? `${BASE_BUCKET_URL}${user.construction_company.logo}`
                  : LOGO
                : LOGO
            }
          />
          {affiliate === 'true' && (
            <Company>
              {user.construction_company && user.construction_company.name}
            </Company>
          )}
          <Username>{`${user.first_name} ${user.last_name}`}</Username>
        </React.Fragment>
      )}
      <Button
        color="link"
        onClick={handleLogOut}
        className="d-flex align-items-center">
        <ExitToAppIcon /> <span className="pl-2">Cerrar sesión</span>
      </Button>

      <NavContainer>
        {affiliate === 'true' && (
          <NavButton
            onClick={() => navigate('/admin/mi-cuenta/proyectos')}
            active={props.location.pathname === '/admin/mi-cuenta/proyectos'}
            className="w-100 text-left"
            color="link">
            Proyectos
          </NavButton>
        )}
        <Divider />
        <NavButton
          onClick={() => navigate('/admin/mi-cuenta/contactos')}
          active={props.location.pathname === '/admin/mi-cuenta/contactos'}
          className="w-100 text-left"
          color="link">
          Contactos
        </NavButton>
        <Divider />
        <NavButton
          className="w-100 text-left"
          color="link"
          onClick={() => navigate('/mis-favoritos')}>
          Favoritos
        </NavButton>
      </NavContainer>
    </ContentLateralBar>
  );
};

// BaseAccount component
const BaseAccount = ({
  loadingUser,
  children,
  setAuthenticate,
  user,
  ...props
}) => {
  const [isPopupVisible, setIsPopupVisible] = useState(true);
  let {affiliate} = user;
  affiliate = affiliate == 'true';

  return (
    <React.Fragment>
      {isPopupVisible && <Popup onClose={() => setIsPopupVisible(false)} />}
      <Row>
        <Aside md={3} className="d-flex flex-column align-items-center">
          <ProfileForm
            loadingUser={loadingUser}
            user={user}
            setAuthenticate={setAuthenticate}
            {...props}
          />
        </Aside>
        <Col md={9}>
          {affiliate && <ContentWrapper>{children}</ContentWrapper>}
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    loadingUser: state.auth.loadingUser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAuthenticate: value => dispatch(setAuthenticate(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseAccount);
