import React from 'react';
import style from './style.module.css';
import { useState, useEffect } from 'react';
import Api from '../../services/api';
import { ProjectImage } from '../com';
import CardNews from '../CardNews/CardNews';
import { Col, Row, Container, Figure } from '@bootstrap-styled/v4';
import { navigate } from '@reach/router';
import { formatDate } from '../../modules/utils';
import Arrow from '../../SvgImg/flechaCircle.svg';
import styled from 'styled-components';
import BlogsSkeleton from '../skeletons/BasicCardSkeleton';
import CardNewsSkeleton from '../skeletons/CardNewsSkeleton'; 

const Title = styled.p`
    font-size: 26px !important;
    font-weight: 700; 
    margin: 1.2rem 0;

`;

const handlerTitle = title => {

  let titleList = title.split("");
  let firtsLetter = titleList.shift();

  return firtsLetter.toUpperCase() + titleList.join("").toLowerCase();
};

const BlogNews = () => {
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setLoading(true);
    let request = Api.blogs
      .find({
        query: {
          $sort: {
            createdAt: -1
          },
          $limit: 9,
          $skip: 0,
        },
      })
      .subscribe(({ data }) => {
        setBlogs(data);
        setLoading(false);
      });
    return () => request.unsubscribe();
  }, []);

  const firstElement = blogs.find(e => {
    let listId = blogs.map(e => e.id);
    let max = Math.max(...listId);
    if (e.id === max) {
      return e;
    }
  });

  // console.log(firstElement);

  return (
    <Container className={style.container_blogs}>
      <Row>
        <Title >Blog recientes</Title>
      </Row>
      <Row>
        <Col md={7}>
          {
            loading ? (
              <BlogsSkeleton />
            ) : (
              <React.Fragment>
                <Row style={{ cursor: 'pointer' }} onClick={() => navigate(`/noticias/${firstElement.title_slug}`)}>
                  {firstElement && (
                    <Figure style={{ borderRadius: '10px', overflow: 'hidden' }}>
                      <ProjectImage
                        style={{ borderRadius: '10px', overflow: 'hidden' }}
                        size="medium_large"
                        src={firstElement.cover_image}
                      />
                    </Figure>
                  )}
                </Row>
                
                <div className={style.content_categori}>
                  <div className={style.categoria_blog}>
                    <p className={style.text_categori}>Categoria</p>
                  </div>
                  <div>
                    <p className={style.title_autor}>{`Por: ${ firstElement.author || 'Contrucaribe'}`}</p>
                  </div>
                </div>
                <Row>
                  {firstElement && (
                    <h2 className={style.title}>{handlerTitle(firstElement.title)}</h2>
                  )}

                  <div className={style.date_logo}>
                    {firstElement && (
                      <p style={{ textTransform: 'capitalize' }}>{formatDate(firstElement.createdAt)}</p>
                    )}
                    <div className={style.logo}>
                      <img src={Arrow} alt="flecha.svg" />
                    </div>
                  </div>
                </Row>
              </React.Fragment>
            )
          }
        </Col>
        <Col md={5}>
          <div>
            {
              loading ? (
                <React.Fragment>
                  {[1, 2, 3, 4].map(i => (
                   <CardNewsSkeleton key={i}/>
                  ))}
                </React.Fragment>) : (

                  blogs
                  .filter(d => d.id !== firstElement.id)
                  .slice(0, 3)
                  .map((e, index) => {
                    return (
                      <CardNews
                        key={index}
                        author={e.author}
                        authorImage={e.author_image}
                        title={e.title}
                        imagen={e.cover_image}
                        categoria={'Vivienda'}
                        title_slug={e.title_slug}
                        createdAt={e.createdAt}
                      />
                    );
                  })

              )
            }
          </div>
          <Row>
            <Col md={5}></Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default BlogNews;
